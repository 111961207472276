import React, { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import InputField from '../../components/inputField/InputField';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { sendSignInLinkToEmail } from 'firebase/auth';
import {auth} from '../../FirebaseConfig';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SignupAPI } from '../../logic/signupApi/signupApi';
import MessageBubble from '../../components/messageBubble/MessageBubble';
import Captcha from '../../components/captcha/Captcha';
import ErrorMessage from '../../components/errorMessage/ErrorMessage';
import Modal from '../../components/modal/Modal';
import ScrollBox from '../../components/scrollBox/ScrollBox';
import Checkbox from '../../components/checkbox/Checkbox';
import Loader from '../../components/loader/Loader';
import { getCountry } from '../../utils/phoneNumberUtils';
import platform from 'platform';
import { useTranslation } from 'react-i18next';

interface RegistrationScreenProps {
  isReferral: boolean;
}

const RegistrationScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  // TO-DO: Refactor referral conditions
  const { register, handleSubmit, formState: { errors, defaultValues } } = useForm();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipAppearTime = 15;

  const [phoneNumber, setPhoneNumber] = useState('');
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [communityName, setCommunityName] = useState<string | null>(null);
  const [associationToCommunity, setAssociationToCommunity] = useState<string>('Member does not claim to belong to community');

  const [refresh, doRefresh] = useState(0);

  const [cType, setCType] = useState('regular');

  const checkReferralId = async () => {
    let url = new URL(window.location.href);

    const code = url.searchParams.get('ref');

    if(code) {
      assignCode(atob(code), true);
    }
  }

  const assignCode = async (code: string, isLink: boolean = true) => {

    setCode(code);

    return await SignupAPI.checkIfInviteCodeIsValid(code).then((result: any) => {
      console.log("Refereal community:", result);

      setCType('hasCode');
      
      setCommunityName(result.communityName);

      if(isLink) {
        setAssociationToCommunity(`Member with a link code for: ${result.communityName}, ${code}`);
      }
      else {
        return `Member with an invite code for: ${result.communityName}, ${code}`
      }

    }).catch((error) => {
      console.log('error while checking invite code: ', error);
      if(isLink) {
        setAssociationToCommunity(`Member tried using non-existing referral code: ${code}`);
      }
      else {
        return `Member tried using non-existing referral code: ${code}`;
      }
    });
  }

  const onSubmit = async (data: any) => {
    
    setIsLoading(true);

    let phone = '';
    if(phoneNumber){
      phone = `+${phoneNumber.trim()}`
    }
    let email = data.email.trim();
    let fullName = data.fullName.trim();
    let referral = data.referral.trim();

    let communityAssoc = associationToCommunity;

    if(referral) {
      communityAssoc = await assignCode(referral, false) as string;
    }

    const deviceInfo = `${platform.name} ${platform.version} on ${platform.os?.family} ${platform.os?.version ? platform.os?.version+' ' : ''}${platform.os?.architecture}`;

    await SignupAPI.validateAndRegisterParticipant({
      recaptchaToken: captcha,
      telephoneMobile: phone,
      email: email,
      fullName: fullName,
      inviteCode: code || referral || null,
      cType: cType,
      associationToCommunity: communityAssoc,
      communityName: communityName || '',
      reference: '',
      deviceInfo: deviceInfo,
      requestURL: window.location.hostname,
      registrationType: 'direct'
    }).then(async () => {
      return await SignupAPI.sendLoginCode({
        whereToSendTheCode: 'email',
        email: email
      }).catch((error: unknown) => {
        setErrMsgTxt("Can not send verifiction code.");
        setErrMsgVisible(true);
        console.log('error while sending login code: ', error)
      })
    }).then((response: any) => {
      if(response.result === 'ok') {
        navigate("/login/verification", {state: {email: email, phone}});
      }
    }).catch((error: any) => {
      console.log('error', error);

      if( error.opCode ) {

        switch (error.opCode) {
          case 'reg/email-taken':
            console.log("Navigate to login with email: ", email);
            navigate("/login", {state: {email: email, phone}});
            break;

          case 'reg/phone-taken':
            console.log("Navigate to login with phone: ", phone);
            navigate("/login/phone", {state: {email: email, phone: phoneNumber.trim()}});
            break;
          
          case 'reg/invalid-name':
            setErrMsgTxt("Invalid name - must be at least 3 characters.");
            setErrMsgVisible(true);
            break;
        
          default:
            break;
        }

      }
      else {
        setErrMsgTxt(error.message);
        setErrMsgVisible(true);
      }

      setIsLoading(false);
      doRefresh(prev => prev + 1);
    });
  };

  const captchaOnChange = (value: string | null) => {
    console.log(value)
    setCaptcha(value);
  }

  const [timer, setTimer] = useState(0);

  //TODO: uncomment when we know what to do with this tooltip
  // useEffect(()=>{
  //   if(timer<tooltipAppearTime){
  //     setTimeout(()=>{
  //       setTimer(timer+1);
  //     }, 1000);
  //   }
  //   if(timer===tooltipAppearTime){
  //     setIsTooltipVisible(true);
  //   }
  // },[timer]);

  const [country, setCountry] = useState("");

    useEffect(() => {
        const country = getCountry();
        country.then((response) => {
            setCountry(response);
        });
    }, []);
    
  useEffect(() => {
    checkReferralId();
  }, []);

  const [errMsgVisible, setErrMsgVisible] = useState(false);
  const [errMsgTxt, setErrMsgTxt] = useState('');

  const email = useLocation().state?.email || null;
  const phone = useLocation().state?.phone || null;

  const [isWaiverModalOpened, setIsWaiverModalOpened] = useState(false);
  const [isWaiverAccepted, setIsWaiverAccepted] = useState(false);

  useEffect(() => {
    setPhoneNumber(phone)
  }, [phone]);

  const [isLoading, setIsLoading] = useState(false);

  const removeMessageBubble = () => {
    setIsTooltipVisible(false);
  }

  return (
    <div className='body-container'>
        <div className='content-container'>

            {
              errMsgVisible && <ErrorMessage message={errMsgTxt} onClose={()=> {setErrMsgVisible(false);}}/> 
            }
            
            <div className='text-container'>
                <text className='text-big'>{t('NEW_MEMBER_REGISTRATION')}</text>
                <text className='text-big'>{t('WELCOME')}</text>
            </div>
            {isWaiverModalOpened && 
            <Modal>
              <>
              <div className='text-container'>
                <text className='text-big'>{t('WAIVER_OF_LIABILITY')}</text>
                <text className='text-medium'>{t('REGISTRATION_SCREEN_TEXT_FIRST')}</text>
              </div>
              <ScrollBox children={
                <>
                  <text className='text-small-bold'>{t('REGISTRATION_SCREEN_TEXT_SECOND')}
                  </text>
                  &nbsp;
                  <text className='text-small'>
                  {t('REGISTRATION_SCREEN_TEXT_THIRD')}
                  {t('REGISTRATION_SCREEN_TEXT_FOURTH')}
                  </text>
                </>}
              />
              <Button title={t('CONTINUE')} onClick={() => {
                setIsWaiverModalOpened(false)
                setIsWaiverAccepted(true);
                }}/> 
            </>
            </Modal>}
            <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
              {
                communityName && 
                <text style={{marginTop: 15, marginBottom: 15}}>
                  <b> {t('REGISTARTION_SCREEN_TEXT_FIFTH')}</b> <span>{communityName}</span>.
                </text>
              }

              <InputField 
                name="fullName" 
                errors={errors} 
                register={register} 
                options={ {required: true,
                  pattern: {
                    value: /^\s*[A-Za-z/./]+(\s+[A-Za-z/./]+)*\s*$/,
                    message: t('INVALID_FULL_NAME')
                  },
                  validate: {
                    minLength: (v) => v.length >= 0,
                }}}
                placeholder={`${t('FULL_NAME')} *`}
              />
              <InputField 
                name="email" 
                errors={errors} 
                register={register} 
                options={ {required: true,
                  pattern: {
                    value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
                    message: t('INVALID_EMAIL')
                  },
                  validate: {
                    minLength: (v) => v.length >= 0,
                }}} 
                defaultValue={email}
                placeholder={`${t('EMAIL_ADDRESS')} *`}
              />
              {/* <PhoneInput country={country ? country : ''} value={phoneNumber} onChange={(phone)=> setPhoneNumber(phone)}/> */}
              {
                // If country is assigned and there is a phone value in the field the value is removed for some reason
                phone ?
                // if we come to this screen from the registration with a phone number,
                // the country is automatically detected from the phone number,
                // so we can bypass the bug by not fetching the country
                <PhoneInput 
                  value={phoneNumber}
                  inputClass={phoneNumber ? 'phone-input-bold' : 'phone-input-regular'}
                  buttonClass={'phone-button'}
                  onChange={(phone) => {
                  setPhoneNumber(phone)
                }}/>
                :
                // if we come here without a phone number to display,
                // we fetch and assign the country
                // and since there is no value that is going to disappear its okay
                <PhoneInput 
                  country={country}
                  inputClass={phoneNumber ? 'phone-input-bold' : 'phone-input-regular'}
                  buttonClass={'phone-button'}
                  value={phoneNumber}
                  onChange={(phone) => {
                  setPhoneNumber(phone)
                }}/>
              }
              {!communityName && <InputField 
                name="referral" 
                errors={errors} 
                register={register} 
                placeholder={t('REFERAL_CODE_OPTIONAL')} isOptional={true}
              />}
              <div style={{marginLeft: 8}}>
                <Checkbox
                  name='acceptWaiver'
                  register={register}
                  errors={errors} 
                  options={ {required: true}} 
                  value={isWaiverAccepted} 
                  onChange={()=> setIsWaiverAccepted(!isWaiverAccepted)}
                >
                  <text className='text-link' onClick={()=> setIsWaiverModalOpened(true)}>{t('REGISTRATION_TEXT_SIXTH')}</text>
                </Checkbox>
              </div>  
              <Captcha onChange={captchaOnChange} refresh={refresh}/>
              {isLoading && <Loader/>}
              <Button title={t('CONTINUE')}/> 
            </form>
          </div>
        <ProgressBar numberOfSteps={2} currentStep={1}/>
        {isTooltipVisible && <MessageBubble removeBubble={removeMessageBubble} title={t('NEED_HELP')} content={t('REGISTRATION_SCREEN_TEXT_SEVENTH')}/>}
        <text className='text-link' onClick={() => navigate('/login')}>{t('LOGIN_INSTEAD')}</text>
    </div>
  )
};

export default RegistrationScreen